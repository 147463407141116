<template>
    <div>
        <v-layout row pt-6>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.name') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.otsShortName') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.otherRelatedName') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.taxNumber') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.address1') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.address2') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.address3') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.number') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.city') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.origin') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.postcode') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.stateProvince') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.country') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.officeTelephone') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.website') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.salesColleague') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.salesTerritory') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.marketSegments') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.unableToAccessAppic') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.certification') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="font-weight-bold">{{ $t('message.contacts') }}</v-flex>
        </v-layout>
        <v-layout row>
            <v-simple-table class="mt-3 appic-table-light specification-table col-md-10 col-xs-12" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="grey lighten-3 py-0 pr-2 pl-2 text-left width-1-pct text-start">{{ $t('message.lineNo') }}</th>
                            <th class="grey lighten-3 py-0 pr-0 pl-2 text-left width-1-pct text-start">{{ $t('message.salutation') }}</th>
                            <th class="grey lighten-3 py-0 pr-0 pl-2 text-left width-15-pct text-start">{{ $t('message.name') }}</th>
                            <th class="grey lighten-3 py-0 pr-0 pl-2 text-left width-10-pct text-start">{{ $t('message.department') }}</th>
                            <th class="grey lighten-3 py-0 pr-0 pl-2 text-left text-start">{{ $t('message.position') }}</th>
                            <th class="grey lighten-3 py-0 pr-0 pl-2 text-left text-start">{{ $t('message.contact') }}</th>
                            <th class="grey lighten-3 py-0 pr-0 pl-2 text-left width-1-pct text-start">{{ $t('message.autoEmailNotifications') }}</th>
                            <th class="grey lighten-3 py-0 pr-0 pl-2 text-left width-1-pct text-start">{{ $t('message.appicAccess') }}</th>
                            <th class="grey lighten-3 py-0 pr-2 pl-2 text-left width-1-pct width-1-pct text-start">{{ $t('message.actions') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><div class="data-holder"></div></td>
                            <td><div class="data-holder"></div></td>
                            <td><div class="data-holder"></div></td>
                            <td><div class="data-holder"></div></td>
                            <td><div class="data-holder"></div></td>
                            <td><div class="data-holder"></div></td>
                            <td><div class="data-holder"></div></td>
                            <td><div class="data-holder"></div></td>
                            <td><div class="data-holder"></div></td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-layout>
    </div>
</template>

<script>
export default {
    name: "BuyerProfileGeneralInfo"
}
</script>

<style scoped>
@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
.data-holder {
    -webkit-animation: flickerAnimation 3s infinite;
    -moz-animation: flickerAnimation 3s infinite;
    -o-animation: flickerAnimation 3s infinite;
    animation: flickerAnimation 3s infinite;
    height: 32px !important;
    border-radius: 16px;
    background-color: lightgray;
    margin-top: 5px;
}
table td.text-start {
    padding-left: 3px !important;
    padding-right: 0px !important;
}
.v-data-table::v-deep tbody td {
    vertical-align: top !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 40px !important;
}
.v-data-table::v-deep thead th {
    font-size: 0.875rem !important;
    font-weight: bold !important;
    color: black !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    height: 21px !important;
}
</style>